export const multiPurchase = `
import FungibleToken from 0xFT
import DapperUtilityCoin from 0xDapperUtilityCoin
import NonFungibleToken from 0xNFT
import MetadataViews from 0xMetadataView
import DigiBuddies from 0xDigibuddies
import DigiBuddiesStorefront from 0xDigibuddies

// This transaction purchases an NFT on a peer-to-peer marketplace (i.e. **not** directly from a dapp). This transaction
// will also initialize the buyer's NFT collection on their account if it has not already been initialized.
// FIRST ARGUMENT OF A P2P PURCHASE TRANSACTION SHOULD ALWAYS BE THE SELLER'S ADDRESS
transaction(merchantAccountAddress: Address, storefrontAddress: Address, listingResourceID: [UInt64],  expectedPrice: UFix64) {
    let batchPurchaseLength: Int
    let nftPrice: UFix64
    let mainDapperUtilityCoinVault: &DapperUtilityCoin.Vault
    let balanceBeforeTransfer: UFix64

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&DigiBuddies.Collection>(from: DigiBuddies.CollectionStoragePath) == nil {
            let collection <- DigiBuddies.createEmptyCollection() as! @DigiBuddies.Collection
            buyer.save(<-collection, to: DigiBuddies.CollectionStoragePath)
            
            buyer.link<&DigiBuddies.Collection{NonFungibleToken.CollectionPublic, DigiBuddies.CollectionPublic}>(
                DigiBuddies.CollectionPublicPath,
                target: DigiBuddies.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

        // Get the storefront reference from the seller
        let storefront = getAccount(storefrontAddress)
            .getCapability<&DigiBuddiesStorefront.Storefront{DigiBuddiesStorefront.StorefrontPublic}>(
                DigiBuddiesStorefront.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        self.batchPurchaseLength = listingResourceID.length
        self.nftPrice = expectedPrice / UFix64(self.batchPurchaseLength)

        // Get a DUC vault from Dapper's account
        self.mainDapperUtilityCoinVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from account storage")
        self.balanceBeforeTransfer = self.mainDapperUtilityCoinVault.balance

        for id in listingResourceID {
            // Get the listing by ID from the storefront
            let listing = storefront.borrowListing(listingResourceID: id)
                ?? panic("No Offer with that ID in Storefront")
            let salePrice = listing.getDetails().salePrice

            let paymentVault <- self.mainDapperUtilityCoinVault.withdraw(amount: self.nftPrice)

            // Get the collection from the buyer so the NFT can be deposited into it
            let nftCollection = buyer.borrow<&NonFungibleToken.Collection{NonFungibleToken.Receiver}>(
                from: DigiBuddies.CollectionStoragePath
            ) ?? panic("Cannot borrow NFT collection receiver from account")


            listing.purchase(
                payment: <- paymentVault, 
                collection: nftCollection
            )

            // Remove listing-related information from the storefront since the listing has been purchased.
            storefront.cleanup(listingResourceID: id)
        
            assert(salePrice == self.nftPrice || salePrice - 0.0 == self.nftPrice, message: "Batch purchase length does not match listingResourceID length")
        }
    }

    execute {}

    // Check that all dapperUtilityCoin was routed back to Dapper
    post {
        self.mainDapperUtilityCoinVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }
}
`