import React from "react";

import "./index.scss";

const Legal = () => {
  return (
    <div className="container d-flex justify-content-center align-items-start flex-column terms">
      <h1>Legal Page for Digibuddies.xyz</h1>
      <p>Effective Date: [Date]</p>
      <p>Welcome to Digibuddies.xyz.</p>
      <p>
        This Legal Page outlines the terms and conditions that govern your use
        of our website.
      </p>
      <p>
        By accessing or using Digibuddies.xyz, you agree to comply with these
        terms and conditions. If you do not agree with any part of these terms,
        please refrain from using our website.
      </p>
      <h3>1. Intellectual Property</h3>
      <div className="sub-content">
        <p>
          a. Ownership: All content and materials on Digibuddies.xyz, including
          but not limited to text, graphics, images, logos, trademarks, and
          software, are the property of Digibuddies.xyz or its licensors and are
          protected by intellectual property laws. You may not use, reproduce,
          modify, or distribute any of these materials without our prior written
          permission.
        </p>
        <p>
          b. User Content: By submitting or posting any content on
          Digibuddies.xyz, you grant Digibuddies.xyz a non-exclusive,
          royalty-free, worldwide, perpetual, and irrevocable right to use,
          reproduce, modify, adapt, publish, translate, distribute, and display
          such content.
        </p>
      </div>
      <h3>2. Disclaimer of Liability</h3>
      <div className="sub-content">
        <p>
          a. Use at Your Own Risk: Your use of Digibuddies.xyz is at your own
          risk. We do not guarantee the accuracy, completeness, or reliability
          of any information or content on our website. We are not responsible
          for any errors, omissions, or actions taken based on the information
          provided on Digibuddies.xyz.
        </p>
        <p>
          b. Third-Party Websites and Content: Digibuddies.xyz may contain links
          to third-party websites or display third-party content. These links
          and content are provided for your convenience, and we do not endorse
          or assume any responsibility for the accuracy, legality, or content of
          these third-party websites. Your use of third-party websites is at
          your own risk.
        </p>
        <p>
          c. Limitation of Liability: To the maximum extent permitted by law,
          Digibuddies.xyz and its affiliates, directors, officers, employees,
          agents, and suppliers shall not be liable for any direct, indirect,
          incidental, consequential, or special damages arising out of or in any
          way related to your use of Digibuddies.xyz, even if advised of the
          possibility of such damages.
        </p>
      </div>
      <h3>3. Indemnification</h3>
      <div className="sub-content">
        <p>
          You agree to indemnify and hold Digibuddies.xyz and its affiliates,
          directors, officers, employees, agents, and suppliers harmless from
          any claims, damages, liabilities, losses, costs, or expenses
          (including reasonable attorneys' fees) arising out of or related to
          your use of Digibuddies.xyz, your violation of these terms and
          conditions, or your violation of any rights of a third party.
        </p>
      </div>
      <h3>4. Governing Law and Jurisdiction</h3>
      <div className="sub-content">
        <p>
          These terms and conditions shall be governed by and construed in
          accordance with the laws of [Jurisdiction]. Any disputes arising out
          of or in connection with these terms and conditions shall be subject
          to the exclusive jurisdiction of the courts of [Jurisdiction].
        </p>
      </div>
      <h3>5. Modifications</h3>
      <div className="sub-content">
        <p>
          Digibuddies.xyz reserves the right to modify, suspend, or discontinue
          any aspect of the website at any time without prior notice. We may
          also modify these terms and conditions from time to time. It is your
          responsibility to review the most current version of the terms and
          conditions regularly.
        </p>
      </div>
      <h3>6. Severability</h3>
      <div className="sub-content">
        <p>
          If any provision of these terms and conditions is deemed invalid or
          unenforceable, the remaining provisions shall continue in full force
          and effect.
        </p>
      </div>
      <h3>7. Entire Agreement</h3>
      <div className="sub-content">
        <p>
          These terms and conditions constitute the entire agreement between you
          and Digibuddies.xyz regarding your use of our website and supersede
          any prior agreements or understandings, whether written or oral.
        </p>
      </div>
      <p>
        If you have any questions or concerns regarding these terms and
        conditions, please contact us at our Contact Us page Thank you for using
        Digibuddies.xyz.
      </p>
    </div>
  );
};

export default Legal;
