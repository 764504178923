import { config } from "@onflow/fcl";
import * as fcl from "@onflow/fcl"
import {send as httpSend} from "@onflow/transport-http"

// mainnet
config({
  "accessNode.api": "https://access-mainnet.onflow.org",
  "discovery.wallet": "https://accounts.meetdapper.com/fcl/authn-restricted",
  'discovery.wallet.method': "POP/RPC",
  'app.detail.title': "Digibuddies",
	'app.detail.icon': "",
  '0xNFT': "0x1d7e57aa55817448",
  "0xDapperUtilityCoin": "0xead892083b3e2c6c",
  "0xFT": "0xf233dcee88fe0abe",
  "0xMetadataView": "0x1d7e57aa55817448",
  "0xDigibuddies": "0x8871331ca2eed93e"
})
// Configure SDK to use HTTP
fcl
  .config()
  .put("accessNode.api", "https://rest-mainnet.onflow.org")
  .put("sdk.transport", httpSend)
