import React from "react";

import './index.scss';

const ExpandMoreIcon = () => {
    return(
        <>
            <span className="icon">
                +
            </span>
        </>
    );
};

export default ExpandMoreIcon;