export const images = {
  imgs: [
    {
      imgSrc: "slide-1.png",
    },
    {
      imgSrc: "rabbit.png",
    },
    {
      imgSrc: "slide-1.png",
    },
    {
      imgSrc: "rabbit.png",
    },
  ],
};

export const FaqData = {
  items: [
    {
      title: "What are Digibuddies?",
      content:
        "Digibuddies are unique digital collectibles stored on a blockchain, giving them verified scarcity and authenticity. They can be used as avatars in our online game and give access to our upcoming community.",
    },
    {
      title: "How do I purchase a Digibuddi?",
      content: `Step 1, create a <a href = "https://meetdapper.com/" target="_blank">Dapper Wallet</a> account here. <br />
      Step 2, use your credit card to purchase your Digibuddi. <br /> 
      Step 3, tell the world that you bought a Digibuddi and tag us on social media.`,
    },
    {
      title: "Why do I need a wallet?",
      content: `A wallet is required to securely store and manage your Digibuddi. <br />
      <a href = "https://meetdapper.com/" target="_blank">Dapper Wallet</a> is our recommended wallet and is easy to use even for beginners.`,
    },
    {
      title:
        "I am having issues with my Dapper Wallet, where I can I get support?",
      content: `Please head to <a href = "https://support.meetdapper.com/hc/en-us/articles/5302377534227-Creating-Your-Dapper-Wallet" target="_blank"> Dapper’s tech support page</a>.`,
    },
    {
      title: "Where can I see my Digibuddi?",
      content: `Your Digibuddi can be viewed in your <a href = "https://meetdapper.com/" target = "_blank">Dapper Wallet</a>.`,
    },
    {
      title: "Can I choose which Digibuddi that I want to purchase?",
      content: `Each Digibuddi purchase is a surprise, similar to buying a pack of trading cards, or unboxing, but you're guaranteed a 100% unique character with special powers, features, characteristics, and rarity. You can check the <a href = "https://nft.flowverse.co/marketplace" target="_blank">Secondary marketplace</a> to see available Digibuddies. In Flowverse, you can see the exact Digibuddi you're buying.`,
    },
    {
      title: "What can I do with my Digibuddi",
      content: `You can show off your Digibuddi to your friends, share it on social media, and use it as your avatar for the upcoming Digibuddies video game. Check out our <a href="#roadmap">roadmap</a> for more details.`,
    },
    {
      title: "Can I sell my Digibuddi?",
      content: `Yes, you can sell your Digibuddi on the <a href = "https://nft.flowverse.co/marketplace" target="_blank">Flowverse marketplace</a> .`,
    },
    {
      title: "When will the donation to BC Children’s Hospital take place?",
      content: `We're proud to commit to donating 20% of the revenue generated from the initial sale of all 750 Digibuddies to <a href = "https://www.bcchf.ca/" target="_blank">BC Children's Hospital</a>. This charitable donation will be made as soon as the project completely sells out, ensuring that we can make the greatest impact possible. Thank you for supporting this important cause with your Digibuddi purchase!`,
    },
  ],
};
