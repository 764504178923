export const getNFTs = `
import DigiBuddies from 0xDigibuddies
import DigiBuddiesStorefront from 0xDigibuddies
import MetadataViews from 0xMetadataView

pub struct NFTData {
    pub let nftId: UInt64
    pub let nftType: String
    pub let thumbnail: String
    pub let name: String
    pub let description: String
    pub let listed: Bool
    pub let price: UFix64?
    pub let listingId: UInt64

    init(nftId: UInt64, nftType: String, thumbnail: String, name: String, description: String, listed: Bool, price: UFix64?, listingId: UInt64) {
        self.nftId = nftId
        self.nftType = nftType
        self.thumbnail = thumbnail
        self.name = name
        self.description = description
        self.listed = listed
        self.price = price
        self.listingId = listingId
    }
}

pub fun main(address: Address): [NFTData] {
    let nftData: [NFTData] = []

    let account = getAccount(address)

    if(account.getCapability<&DigiBuddies.Collection{DigiBuddies.CollectionPublic}>(DigiBuddies.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&DigiBuddies.Collection{DigiBuddies.CollectionPublic}>(DigiBuddies.CollectionPublicPath).borrow()!
        let digibuddiesIds = marketCollectionRef.getIDs()

        for nftID in digibuddiesIds {
            let nft = marketCollectionRef.borrowDigiBuddies(id: nftID) 
                        ?? panic("Could not borrow a reference to the collection")


            let view = nft.resolveView(Type<DigiBuddies.DigiBuddiesMetadata>())!
            let display = view as! DigiBuddies.DigiBuddiesMetadata
            let nftID = nft.id
            let url = "https://plum-active-grouse-321.mypinata.cloud/ipfs/".concat(display.image)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "DigiBuddies"
            var listingId: UInt64 = 0

            if(account.getCapability<&DigiBuddiesStorefront.Storefront{DigiBuddiesStorefront.StorefrontPublic}>(DigiBuddiesStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DigiBuddiesStorefront.Storefront{DigiBuddiesStorefront.StorefrontPublic}>(DigiBuddiesStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }   

    return nftData
}
`