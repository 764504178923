import React, { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import { getNFTs } from "../../cadence/script/getNFTs";

const ShowNFT = () => {
  const [nfts, setNfts] = useState([]);
  const [user, setUser] = useState({ loggedIn: null });

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  useEffect(() => {
    getNFTsFunc();
  }, [user]);

  const getNFTsFunc = async () => {
    if (user.loggedIn === null) return;

    const result = await fcl.query({
      cadence: getNFTs,
      args: (arg, t) => [arg(user?.addr, t.Address)],
      limit: 9999
    });

    console.log("result - ", result);
    setNfts(result);    
  }

  return (
    <div className="mint-content">
      <div className="container">
        {user.addr ? 
        <>
        <h2>NFTs You Own</h2>
        <div className="nft-div">
          {nfts.map((nft, index) => {
            return (
              <div key={index} className="nft-card">
                <img src={nft.thumbnail} alt="nft" className="slider-item-bg" />
                <p className="mt-4">Name: {nft.name}</p>
                <p>Description: {nft.description}</p>
              </div>
            );
          })}
        </div></> : <h2>Please join wait list!</h2> } 
      </div>
    </div>
  );
};

export default ShowNFT;
