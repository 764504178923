import React from "react";
import Mint from "../component/Mint"
const MintPage = () => {
    return (
        <>
            <Mint />
        </>
    );
};

export default MintPage;
