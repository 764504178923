import React, { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { Button, Typography, CircularProgress } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Slider from "react-slick";

import "./index.scss";
import { Mint_Txn } from "../../cadence/transaction/mint";
import { getListingId } from "../../cadence/script/getListingId";
import { multiPurchase } from "../../cadence/transaction/multiPurchase";
import { ToastErrMsg, ToastSuccessMsg, ToastWarnMsg } from "../Toast";

var settings = {
  className: "",
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
  arrows: false,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

const Mint = () => {
  const [mintCount, setMintCount] = useState(0);
  const [user, setUser] = useState({ loggedIn: null });
  const [listedNFT, setListedNFT] = useState([]);
  const [txProgress, setTxProgress] = useState(false);

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  // console.log(user.addr);

  useEffect(() => {
    getListingIdFunc();
  }, [user]);

  const getListingIdFunc = async () => {
    const res = await fcl.query({
      cadence: getListingId,
      args: (arg, t) => [arg("0x8871331ca2eed93e", t.Address)], //mainnet
      // args: (arg, t) => [arg("0x08f72a6ac4045df8", t.Address)], //testnet
      limit: 9999
    });

    setListedNFT(res);
    console.log(res.length);
  }

  const handlePrev = () => {
    if (mintCount <= 0) {
      return;
    }

    setMintCount(mintCount - 1);
  };
  const handleNext = () => {
    setMintCount(mintCount + 1);
  };

  const handleMint = async () => {
    if(mintCount === 0){
      ToastErrMsg("Please mint at least 1 NFT!");
      return;
    }

    setTxProgress(true);

    var listedID = [];
    for (let i = 0; i < mintCount; i++) {
      listedID.push(listedNFT[i]);
    }

    var price = parseFloat(mintCount * 50.0);

    console.log("listedId - ", listedID);

    try {
      const res = await fcl.mutate({
        cadence: multiPurchase,
        args: (arg, t) => [
          arg('0xd80e19e5bbeb691c', t.Address),
          arg('0x8871331ca2eed93e', t.Address),
          arg(listedID, t.Array(t.UInt64)),
          arg(price + ".0", t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 9999,
      });

      fcl.tx(res).subscribe((res) => {
        if (res.status === 4 && res.errorMessage === "") {
          ToastSuccessMsg("NFT is successfully Minted!");
          // window.location.reload(false);
          setTxProgress(false);
        }
      });

      console.log("txid - ", res);
      ToastErrMsg(res.errorMessage);
      // console.log("error:---", res.errorMessage);
      setTxProgress(false);
    } catch (error) {
      console.log("err - ", error);
      ToastErrMsg(error);
      setTxProgress(false);
    }
  };

  return (
    <div className="mint-content">
      <div className="container">
        <h2>How Many Digibuddies Would you Like to Purchase?</h2>
        <div className="row">
          <div className="col-lg-6">
            <Slider {...settings}>
              <div className="slider-item">
                <img className="slider-item-bg" src="Mint1.png" alt="sailor" />
              </div>
              <div className="slider-item">
                <img className="slider-item-bg" src="Mint2.png" alt="tiger" />
              </div>
              <div className="slider-item">
                <img className="slider-item-bg" src="Mint3.png" alt="sailor" />
              </div>
              <div className="slider-item">
                <img className="slider-item-bg" src="Mint4.png" alt="sailor" />
              </div>
              <div className="slider-item">
                <img className="slider-item-bg" src="Mint5.png" alt="sailor" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-6">
            <div className="mint-desc-content">
              <h2 className="text-white">
                Launching Sept 26, 2023
              </h2>
              <h4>Price: 50 USD or 100 Flow each</h4>
              <p></p>
              <p>
                Remember, 20% of every purchase will be donated to BC Children's
                Hospital
              </p>
              <p>
                PS..... after you make your purchase, you will be able to see
                the random Digibuddi that you have purchased including its
                traits, superpowers and levels of rarity!
              </p>
              <h4>Join the Digibuddi hype!</h4>

              <div className="row mt-5">
                <div className="col-3">
                  <h4>Step1</h4>
                </div>
                <div className="col-6 text-center">
                  <div>
                    <Button onClick={handlePrev}>
                      <ArrowBackIosNewOutlinedIcon />
                    </Button>
                    <Button color="success" variant="contained">
                      {mintCount}
                    </Button>
                    <Button onClick={handleNext}>
                      <ArrowForwardIosOutlinedIcon />
                    </Button>
                  </div>
                </div>
                <div className="col-3"></div>
              </div>

              <div className="row d-flex align-items-center mt-3">
                <div className="col-3"></div>
                <div className="col-6 text-center">
                  <div className="">
                    <Button variant="contained" color="success">
                      ${parseFloat(50 * mintCount).toFixed(2)}
                    </Button>
                  </div>
                </div>
                <div className="col-3">
                  <Typography variant="h4" className="my-auto">
                    TotalCost
                  </Typography>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-3">
                  <h4>Step2</h4>
                </div>
                <div className="col-6 d-flex justify-content-center">
                  {txProgress ?
                  <CircularProgress color="secondary" />
                  :
                  <Button
                    onClick={handleMint}
                    variant="contained"
                    color="success"
                  >
                    Buy now
                  </Button>
                  }
                  
                  {/* <Button
                    variant="contained"
                    color="success"
                    disabled
                    className="text-white"
                  >
                    Coming soon
                  </Button>        */}
                </div>
                <div className="col-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
