export const getListingId = `
import MetadataViews from 0xMetadataView
import DigiBuddiesStorefront from 0xDigibuddies

// IMPORTANT: Parameter list below should match the parameter list passed to the associated purchase txn
// Please also make sure that the argument order list should be same as that of the associated purchase txn

pub fun main(address: Address): [UInt64] {
    let account = getAccount(address)
    let marketCollectionRef = account.getCapability<&DigiBuddiesStorefront.Storefront{DigiBuddiesStorefront.StorefrontPublic}>(
        DigiBuddiesStorefront.StorefrontPublicPath
        ).borrow()
        ?? panic("Could not borrow market collection from address")
        return marketCollectionRef.getListingIDs()
}
`