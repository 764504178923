import React from "react";
import Whitelist from "../component/Whitelist";
import Faq from "../component/Faq";

const WhiteList = () => {
  return (
    <>
      <Whitelist />
      <Faq />
    </>
  );
};

export default WhiteList;
