import React from "react";

import "./index.scss";

const Policy = () => {
  return (
    <div className="container d-flex justify-content-center align-items-start flex-column terms">
      <h1>Privacy Policy for Digibuddies.xyz</h1>
      <p>At Digibuddies.xyz, we value the privacy and security of our users.</p>
      <p>
        This Privacy Policy outlines the types of personal information we
        collect, how we use and protect that information, and the choices you
        have regarding your personal data.
      </p>
      <p>
        By accessing or using Digibuddies.xyz, you agree to the terms of this
        Privacy Policy.
      </p>
      <h3>1. Information We Collect</h3>
      <div className="sub-content">
        <p>
          a. Personal Information: We may collect personal information such as
          your name, email address, and contact information when you voluntarily
          provide it to us. This information is collected when you register an
          account, communicate with us, participate in surveys or promotions, or
          use our services.
        </p>
        <p>
          b. Log Data: We collect information that your browser sends whenever
          you visit Digibuddies.xyz. This may include your IP address, browser
          type and version, the pages you visit, the time and date of your
          visit, and other statistics.
        </p>
        <p>
          c. Cookies and Similar Technologies: We use cookies and similar
          technologies to enhance your experience on our website. These
          technologies help us collect information about your usage patterns and
          preferences, such as your language preference, and enable us to
          provide a more personalized experience.
        </p>
      </div>
      <h3>2. Use of Information</h3>
      <div className="sub-content">
        <p>a. Personal Information: We may use your personal information to:</p>
        <div className="sub-content">
          <p>⚬ Provide and improve our services</p>
          <p>⚬ Respond to your inquiries and communicate with you</p>
          <p>⚬ Personalize your experience and deliver relevant content</p>
          <p>
            ⚬ Send you newsletters, updates, and promotional materials (if you
            opt-in)
          </p>
          <p>
            ⚬ Conduct research and analysis to improve our website and services
          </p>
        </div>
        <p>
          b. Log Data and Cookies: We use log data and cookies to analyze
          trends, administer the website, track users' movements, and gather
          demographic information. This information helps us improve our
          services, monitor the effectiveness of our marketing campaigns, and
          enhance the overall user experience.
        </p>
      </div>
      <h3>3. Data Sharing and Disclosure</h3>
      <div className="sub-content">
        <p>
          a. Third-Party Service Providers: We may engage trusted third-party
          service providers to perform certain business functions on our behalf,
          such as hosting, analytics, marketing, and customer support. These
          providers have access to personal information as necessary to fulfill
          their functions but may not use it for any other purpose.
        </p>
        <p>
          b. Legal Requirements: We may disclose personal information if
          required to do so by law or in response to valid requests by public
          authorities.
        </p>
      </div>
      <h3>4. Data Security</h3>
      <div className="sub-content">
        <p>
          We employ industry-standard security measures to protect the personal
          information we collect. However, no method of transmission or storage
          is completely secure, and we cannot guarantee absolute security. We
          encourage you to take steps to protect your personal information and
          to notify us immediately of any unauthorized use or security breaches.
        </p>
      </div>
      <h3>5. Your Rights and Choices</h3>
      <div className="sub-content">
        <p>
          a. Access and Update: You have the right to access, update, and
          correct your personal information. You can do this by logging into
          your account or by contacting us directly.
        </p>
        <p>
          b. Communications: You have the option to opt-in or opt-out of
          receiving promotional communications from us. You can manage your
          communication preferences by following the instructions provided in
          the emails we send or by contacting us.
        </p>
        <p>
          c. Cookies: Most web browsers allow you to control cookies through
          their settings preferences. However, disabling cookies may limit your
          access to certain features of Digibuddies.xyz.
        </p>
      </div>
      <h3>6. Children's Privacy</h3>
      <div className="sub-content">
        <p>
          Digibuddies.xyz is not intended for individuals under the age of 13.
          We do not knowingly collect personal information from children. If we
          become aware that we have collected personal information from a child
          under 13 without parental consent, we will take steps to delete that
          information.
        </p>
      </div>
      <h3>7. Changes to the Privacy Policy</h3>
      <div className="sub-content">
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          material changes by posting the revised Privacy Policy on
          Digibuddies.xyz. We encourage you to review
        </p>
      </div>
    </div>
  );
};

export default Policy;
