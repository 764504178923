import React from "react";
import ShowNFT from "../component/ShowNFT"

const ShowNFTs = () => {
    return (
        <>
            <ShowNFT />
        </>
    );
};

export default ShowNFTs;
